import React from 'react';
import {useRecoilValue} from 'recoil';
import {DialogState, DialogStateProps} from 'hooks/states';
import {IfMatch, IfMatchCase, IfMatchElse} from '@un7qi3-ui/react/IfMatch';
import WarningDialog from './WarningDialog';
import AlertDialog from './AlertDialog';
import FatebookDialog from './FatebookDialog';

const Dialog = React.memo(() => {
  const dialogOption = useRecoilValue<DialogStateProps>(DialogState);

  return (
    <IfMatch condition={dialogOption.type} deps={[dialogOption.type]}>
      <IfMatchCase then="warning" element={<WarningDialog {...dialogOption} />} />
      <IfMatchCase then="fatebook" element={<FatebookDialog {...dialogOption} />} />
      <IfMatchElse element={<AlertDialog {...dialogOption} />} />
    </IfMatch>
  );
});

export default Dialog;
